import ImageText from "./ImageText/ImageText";
import "./ImagesTexts.css";

const ImagesTexts = ({
  wrapperAnimation,
  isMobile = false,
  setCurrentPage,
}) => {
  return (
    <div className={`image-texts-container ${isMobile ? "mobile" : ""}`}>
      <div className="image-text-container">
        <ImageText
          image="/media/img/Image_1_Accueil.png"
          title="Web Design"
          text="Chez 414Dev nous créons des sites web sur mesure et engageants, offrant à chacun de nos clients une expérience numérique unique et captivante. Nous concevons entièrement tous nos produits de A à Z pour vous garantir des solutions numériques captivantes et adaptées à vos besoins."
          wrapperAnimation={wrapperAnimation}
          isMobile={isMobile}
          setCurrentPage={
            isMobile ? () => {} : () => setCurrentPage("webDesign")
          }
        />
      </div>
      <div className="image-text-container">
        <ImageText
          image="/media/img/Image_2_Accueil.png"
          title="Marketing Digital"
          text="Notre agence web, spécialisée en création digitale, sublime votre identité visuelle et votre branding pour les rendre incontournables en ligne. Nous mettons en lumière l'histoire unique de votre marque à travers des stratégies créatives et des campagnes percutantes, garantissant une présence digitale forte, cohérente et mémorable."
          wrapperAnimation={wrapperAnimation}
          isMobile={isMobile}
          setCurrentPage={isMobile ? () => {} : () => setCurrentPage("SEO")}
        />
      </div>
      <div className="image-text-container">
        <ImageText
          image="/media/img/Image_3_Accueil.png"
          title="Sous-Traitance"
          text="Forte de son expertise, notre agence digitale propose des services de sous-traitance sur-mesure aux entreprises souhaitant enrichir leur offre avec la création de sites web. Offrez à vos clients des solutions web professionnelles et performantes, tout en vous appuyant sur notre savoir-faire éprouvé."
          wrapperAnimation={wrapperAnimation}
          isMobile={isMobile}
          setCurrentPage={
            isMobile ? () => {} : () => setCurrentPage("sous-Traitance")
          }
        />
      </div>
    </div>
  );
};

export default ImagesTexts;
