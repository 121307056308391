import { React, useEffect, useRef } from "react";
import gsap from "gsap";
import "./SideMenu.css";

const SideMenu = ({ setCurrentPage = () => {} }) => {
  const panelRef = useRef();
  const linksRef = useRef();

  const link1Ref = useRef();
  const link2Ref = useRef();
  const link3Ref = useRef();
  const link4Ref = useRef();

  useEffect(() => {
    const t1 = gsap.timeline({ paused: true });
    t1.fromTo(
      panelRef.current,
      { width: "10vw" }, // Start from expanded width
      { width: "50vw", duration: 0.3, ease: "power1" } // Retract to original width
    );

    panelRef.current.addEventListener("mouseenter", () => {
      t1.restart();
    });
    panelRef.current.addEventListener("mouseleave", () => {
      t1.reverse();
    });

    const linkRefs = [link1Ref, link2Ref, link3Ref, link4Ref];

    linkRefs.forEach((linkRef, index) => {
      gsap.set(linkRef.current, { opacity: 0 });

      const h3 = linkRef.current.previousElementSibling;

      const linkOpacity = gsap.fromTo(
        linkRef.current,
        { opacity: 0 },
        {
          opacity: 1,
          duration: 0.3,
          paused: true,
          ease: "power2.in",
        }
      );

      const linkrotation = gsap.fromTo(
        linkRef.current,
        { rotation: 0 },
        {
          duration: 0.3,
          rotation: 135,
          paused: true,
        }
      );

      h3.addEventListener("mouseenter", () => {
        linkOpacity.play();
        linkrotation.play();
      });

      h3.addEventListener("mouseleave", () => {
        linkOpacity.reverse();
        linkrotation.reverse();
      });

      h3.addEventListener("click", () => {
        t1.reverse();
      });
    });

    return () => {};
  }, []);

  return (
    <div className={`side_menu passive`} ref={panelRef}>
      <div className="animation_video">
        <img src="/logo.png" alt="Logo" />
      </div>
      <div className="bars">
        <div className="bar"></div>
        <div className="bar"></div>
        <div className="bar"></div>
      </div>

      <div className="links" ref={linksRef}>
        <div className="link_wrapper">
          <div
            className="link"
            onClick={() => {
              setCurrentPage("mainPage");
            }}
          >
            <h3>Accueil</h3>
            <span ref={link4Ref}></span>
          </div>
          <div
            className="link"
            onClick={() => {
              setCurrentPage("webDesign");
            }}
          >
            <h3>WebDesign</h3>

            <span ref={link1Ref}></span>
          </div>
          <div
            className="link"
            onClick={() => {
              setCurrentPage("SEO");
            }}
          >
            <h3>SEO</h3>
            <span ref={link2Ref}></span>
          </div>
          <div
            className="link"
            onClick={() => {
              setCurrentPage("sous-Traitance");
            }}
          >
            <h3>Sous-Traitance</h3>
            <span ref={link3Ref}></span>
          </div>
        </div>

        <div className="CGU">
          <a
            href="/media/documents/CGU_414Dev.pdf"
            target="_blank"
            rel="noopener noreferrer"
          >
            <h3>CGU</h3>
          </a>
        </div>
      </div>
    </div>
  );
};

export default SideMenu;
