import { React } from "react";
import "./Sous-Traitance.css";
import ChangingImageAndBlackText from "../Components/Desktop/ChangingImageAndBlackText/ChangingImageAndBlackText";
import TextImageBlock from "../Components/Desktop/TextImageBlock/TextImageBlock";
import ImageRectangle from "../Components/Desktop/ImageRectangle/ImageRectangle";
import MobileLandingPanel from "../Components/Mobile/MobileLandingPanel/MobileLandingPanel";
import MobileContent from "../Components/Mobile/MobileContent/MobileContent";
import MobileImage from "../Components/Mobile/MobileImage/MobileImage";
import ContactForm from "../../MainPage/MainPageComponents/ContactForm/ContactForm";
import Arguments from "../SEO/Arguments/Arguments";
import "../AnnexePages.css";

const SousTraitance = ({
  setCurrentPage = () => {},
  urls = [
    "/media/img/Image_3_Accueil.png",
    "/media/img/Image_2_Accueil.png",
    "/media/img/Image_3_Accueil.png",
  ],
  black_box_title = "Sous-Traitance",
  black_box_content = "Votre Partenaire Sous-Traitance de Confiance",
  TextImageBlock_url = "/media/img/SousTraitance/Image_2_Sous_Traitance.png",
  TextImageBlock_titre = "Expertise Fiable",
  TextImageBlock_text = "Vous êtes une agence de communication et recherchez un partenaire de confiance pour gérer tous vos besoins digitaux ? Notre service de sous-traitance est conçu sur mesure pour vous. Confiez-nous vos projets et concentrez-vous sur ce que vous faites de mieux. Contactez-nous dès aujourd'hui pour une collaboration réussie !",
  ImageRectangle_urls = [
    [
      "/media/img/SousTraitance/Image_3_SousTraitance.png",
      "/media/img/SousTraitance/Image_4_SousTraitance.png",
      "Nous vous accompagnons à chaque étape du projet, pour une collaboration réussie et durable.",
      "Nous nous engageons à vous offrir une totale transparence tout au long du projet.",
    ],
  ],
  isMobile = false,
}) => {
  return (
    <section
      className={`SousTraitance ${isMobile ? "mobile" : ""} annexe_page`}
    >
      {!isMobile && (
        <div className="details_wrapper">
          <ChangingImageAndBlackText
            urls={urls}
            black_box_content={black_box_content}
            black_box_title={black_box_title}
          />
          <TextImageBlock
            url={TextImageBlock_url}
            titre={TextImageBlock_titre}
            text={TextImageBlock_text}
          />
          <ImageRectangle urls={ImageRectangle_urls} />
        </div>
      )}
      {isMobile && (
        <div className="mobile_wrapper">
          <MobileLandingPanel
            title="Sous-Traitance"
            subtitle="Votre Partenaire Sous-Traitance de Confiance"
          />
          <MobileContent
            title="Une Expertise Fiable"
            subtitle="Votre Partenaire Sous-Traitance de Confiance"
            content="Vous êtes une agence de communication et recherchez un partenaire de confiance pour gérer tous vos besoins digitaux ? Notre service de sous-traitance est conçu sur mesure pour vous. Confiez-nous vos projets et concentrez-vous sur ce que vous faites de mieux. Contactez-nous dès aujourd'hui pour une collaboration réussie !"
          />
          <MobileImage url={TextImageBlock_url} />
        </div>
      )}
      <Arguments
        isMobile={isMobile}
        title="Notre Offre de Sous-Traitance"
        titles={[
          "Expansion en ligne",
          "Pourquoi nous choisir ?",
          "Notre Processus",
          "Venez en discuter",
        ]}
        contents={[
          "Il est très important aujourd'hui pour une agence de design ou de communication de pouvoir s'étendre en ligne. C'est un domain qui prend de plus en plus de part de marché et qui marche main dans la main avec une présence physique",
          "Nous sommes une équipe de développeurs expérimentés et toujours à la pointe des dernières technologies. Tous les designs auquels vous pouvez penser, nous pouvons les faire. De plus, nous nous engageons à une transparence totale tout au long de nos collaborations.",
          "Nous prenons le temps de comprendre les besoins de votre entreprise et de vos clients, puis, vous n'avez plus qu'à proposer à vos clients des sites internets et nous les déléguer, prenant votre part dans l'offre. Nous nous occupons de tout le reste, de l'entretien avec le client à la finilisation du projet.",
          "Ultimement, le meilleur moyen de savoir si nous sommes le bon partenaire pour vous est de discuter de votre projet. Contactez nous et nous échangerons ensemble sur les modalités de cette future collaboration.",
        ]}
      />
      <ContactForm isAnnexePage={true} />
    </section>
  );
};

export default SousTraitance;
