import React, { useState } from "react";
import "./ContactForm.css";

const ContactForm = ({ isAnnexePage }) => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    message: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const response = await fetch("/api/send-email", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(formData),
    });
    if (response.ok) {
      alert("Email sent successfully!");
    } else {
      alert("Failed to send email.");
    }
  };

  return (
    <div
      className={`contact-form-container ${
        isAnnexePage ? "vertical-layout" : ""
      }`}
    >
      <div className="contact-form-header">
        <h2>Contactez Nous</h2>
        <p>
          Envie d'en savoir plus sur notre entreprise ou de discuter de vos
          projets ? Nous sommes à votre écoute ! Remplissez ce formulaire ou
          écrivez-nous directement à contact@414.dev, et ensemble, donnons vie à
          vos idées
        </p>
      </div>
      <form className="contact-form" onSubmit={handleSubmit}>
        {" "}
        {/* Added onSubmit handler */}
        <div className="form-group">
          <label htmlFor="name">Name</label>
          <input
            type="text"
            id="name"
            name="name"
            required
            onChange={handleChange}
            value={formData.name}
          />
        </div>
        <div className="form-group">
          <label htmlFor="email">Email</label>
          <input
            type="email"
            id="email"
            name="email"
            required
            onChange={handleChange}
            value={formData.email}
          />
        </div>
        <div className="form-group">
          <label htmlFor="message">Description du projet</label>
          <textarea
            id="message"
            name="message"
            required
            onChange={handleChange}
            value={formData.message}
          />
        </div>
        <button type="submit" className="submit-button">
          Envoyer
        </button>
      </form>
    </div>
  );
};

export default ContactForm;
