import "./DescriptionHeader.css";
import { useEffect } from "react";
import { gsap } from "gsap";

const Header = ({ header }) => {
  return (
    <div className="description-header">
      <h1>{header.title}</h1>
      <p>{header.description}</p>
    </div>
  );
};

export default Header;
