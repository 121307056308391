import "./Block.css";
import { useEffect, useRef } from "react";
import { gsap } from "gsap";

const Block = ({ block }) => {
  const titleRef = useRef(null);
  const descriptionRef = useRef(null);

  useEffect(() => {
    gsap.fromTo(titleRef.current, { opacity: 0 }, { opacity: 1, duration: 1 });
    gsap.fromTo(
      descriptionRef.current,
      { opacity: 0 },
      { opacity: 1, duration: 1, delay: 0.5 }
    );
  }, []);
  return (
    <div className="block">
      <div className="block-content">
        <div className="block-title">
          <h2 ref={titleRef}>{block.title}</h2>
        </div>
        <div className="block-description">
          <p ref={descriptionRef}>{block.description}</p>
        </div>
      </div>
    </div>
  );
};

export default Block;
