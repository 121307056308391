import "./Header.css";

const Header = ({
  title = "PERSONNALISABLE",
  subtitle = "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
  textStyle,
}) => {
  return (
    <div className="changing-font-header">
      <h1 style={textStyle}>{title}</h1>
      <h2>{subtitle}</h2>
    </div>
  );
};

export default Header;
