import React, { useState } from "react";
import Header from "./Header/Header";
import Content from "./Content/Content";
import "./ChangingFont.css";
import "./Content/Content.css";
import "./Header/Header.css";

const ChangingFont = () => {
  const [fontWeight, setFontWeight] = useState(400);
  const [textColor, setTextColor] = useState(0);
  const [obliqueAngle, setObliqueAngle] = useState(0);

  const getTextColor = (value) => {
    const r = Math.round(245 + (255 - 245) * (value / 100));
    const g = Math.round(245 * (1 - value / 100));
    const b = Math.round(245 * (1 - value / 100));
    return `rgb(${r}, ${g}, ${b})`;
  };

  const textStyle = {
    fontWeight: fontWeight,
    color: getTextColor(textColor),
    fontStyle: `oblique ${obliqueAngle}deg`,
  };

  return (
    <div className="changing-font-container">
      <Header
        title="PERSONNALISABLE"
        subtitle="Chez 414Dev, nous créons des sites web sur mesure, parfaitement adaptés aux besoins de chaque client. Notre objectif : offrir à nos partenaires une expérience digitale unique et engageante, qui les distingue et renforce leur présence en ligne"
        textStyle={textStyle}
      />
      <Content
        fontWeight={fontWeight}
        setFontWeight={setFontWeight}
        textColor={textColor}
        setTextColor={setTextColor}
        obliqueAngle={obliqueAngle}
        setObliqueAngle={setObliqueAngle}
        textStyle={textStyle}
      />
    </div>
  );
};

export default ChangingFont;
