import React from "react";
import "./TextImageBlock.css";
import { useEffect, useRef } from "react";
import gsap from "gsap";

const TextImageBlock = ({
  url = "/img/Image_2.png",
  titre = "Titre",
  text = "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas cursus vel justo tempus accumsan. Phasellus libero mi, aliquet eget volutpat sed, volutpat id lorem. Integer sollicitudin mi in eleifend lobortis. Proin eu lacus sit amet est elementum semper quis at",
}) => {
  const text_image_block_wrapper = useRef(null);
  useEffect(() => {
    const tl = gsap.timeline();
    tl.fromTo(
      text_image_block_wrapper.current,
      { opacity: 0, y: 20 },
      { opacity: 1, y: 0, duration: 1, delay: 0.5, ease: "power2.inOut" }
    );
    tl.fromTo(
      text_image_block_wrapper.current.querySelector(".image_wrapper img"),
      { "--spread": 0 },
      { "--spread": 10, duration: 1, ease: "power1.in" }
    );
  }, []);
  return (
    <div className="text_image_block_wrapper" ref={text_image_block_wrapper}>
      <div className="text_image_block">
        <div className="text">
          <h1>{titre}</h1>
          <h3>{text}</h3>
        </div>
        <div className="image_wrapper">
          <img src={url} alt={titre} />
        </div>
      </div>
    </div>
  );
};
export default TextImageBlock;
