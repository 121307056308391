import React, { useEffect, useRef } from "react";
import { gsap } from "gsap";
import "./MobileContent.css";

const MobileContent = ({ title, subtitle, content }) => {
  const textRefs = useRef([]);

  useEffect(() => {
    const animation = gsap.fromTo(
      textRefs.current,
      {
        opacity: 0,
        y: 20,
      },
      {
        opacity: 1,
        y: 0,
        stagger: 0.3,
        delay: 1,
        duration: 0.5,
        ease: "power1.out",
      }
    );

    return () => {
      animation.kill();
    };
  }, []);

  return (
    <div className="mobile-content">
      <div className="mobile-content-wrapper">
        <div className="mobile-content-text">
          <h1
            ref={(el) => (textRefs.current[0] = el)}
            className="mobile-content-title"
          >
            {title}
          </h1>
          <p
            ref={(el) => (textRefs.current[1] = el)}
            className="mobile-content-subtitle"
          >
            {subtitle}
          </p>
          <p
            ref={(el) => (textRefs.current[2] = el)}
            className="mobile-content-content"
          >
            {content}
          </p>
        </div>
      </div>
    </div>
  );
};

export default MobileContent;
