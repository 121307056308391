import { useEffect, useState } from "react";
import { gsap } from "gsap"; // Import GSAP
import "./CustomCursor.css";

/* const EyeCursor = ({ color = "white" }) => {
  return (
    <div className="custom_cursor_wrapper eyeball">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        version="1.1"
        width="60px"
        height="60px"
        viewBox="0 0 419 247"
        style={{
          shapeRendering: "geometricPrecision",
          textRendering: "geometricPrecision",
          imageRendering: "optimizeQuality",
          fillRule: "evenodd",
          clipRule: "evenodd",
        }}
      >
        <g>
          <path
            style={{ fill: color }} // Removed opacity and applied fill directly
            d="M 206.5,8.5 C 287.49,10.2445 352.656,42.9111 402,106.5C 409.333,117.833 409.333,129.167 402,140.5C 346.966,210.318 274.8,242.485 185.5,237C 114.433,228.897 57.5999,196.397 15,139.5C 8.50624,127.414 9.17291,115.748 17,104.5C 65.135,43.1816 128.302,11.1816 206.5,8.5 Z M 191.5,37.5 C 262.491,33.6496 321.657,57.9829 369,110.5C 372.167,114.833 375.333,119.167 378.5,123.5C 357.065,153.095 329.732,175.595 296.5,191C 220.002,222.352 148.002,214.352 80.5,167C 64.6095,154.277 50.6095,139.777 38.5,123.5C 76.9721,72.7587 127.972,44.0921 191.5,37.5 Z"
          />
        </g>
        <g>
          <path
            style={{ fill: color }} // Removed opacity and applied fill directly
            d="M 199.5,52.5 C 218.368,50.7179 235.702,54.8846 251.5,65C 278.878,87.4787 287.045,115.645 276,149.5C 257.973,185.428 229.139,199.261 189.5,191C 157.092,179.923 140.425,157.256 139.5,123C 139.567,116.465 140.067,109.965 141,103.5C 150.78,75.2125 170.28,58.2125 199.5,52.5 Z"
          />
        </g>
      </svg>
    </div>
  );
}; */

const DownArrow = ({ color }) => {
  return (
    <div className="down_arrow">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="50px" // Increased size
        height="50px" // Increased size
        viewBox="0 0 24 24"
        fill="none"
        stroke={color} // Use the color prop
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <line x1="12" y1="5" x2="12" y2="19" />
        <polyline points="19 12 12 19 5 12" />
      </svg>
    </div>
  );
};

const CustomCursor = ({
  image_remanante = true,
  color = "#000000",
  arrowColor = "white",
  showArrow,
}) => {
  const [cursorPosition, setCursorPosition] = useState({ x: 0, y: 0 });
  const [isTransitioning, setIsTransitioning] = useState(false);

  const handleMouseMove = (event) => {
    setCursorPosition({ x: event.clientX, y: event.clientY });
  };

  useEffect(() => {
    if (showArrow) {
      gsap.to(".down_arrow", {
        y: 10,
        repeat: -1,
        yoyo: true,
        duration: 0.5,
      });
    }

    window.addEventListener("mousemove", handleMouseMove);

    return () => {
      window.removeEventListener("mousemove", handleMouseMove);
    };
  }, [showArrow]);

  return (
    <div
      className={`custom_cursor ${isTransitioning ? "fade-out" : ""}`}
      style={{
        left: `${cursorPosition.x}px`,
        top: `${cursorPosition.y}px`,
      }}
    >
      {showArrow ? (
        <DownArrow color={arrowColor} /> // Pass the arrow color prop
      ) : (
        <>
          <div className="custom_cursor_wrapper basis"></div>
        </>
      )}
    </div>
  );
};

export default CustomCursor;
