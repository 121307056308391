import React from "react";
import "./Content.css";
import gsap from "gsap";
const Content = ({
  fontWeight,
  setFontWeight,
  textColor,
  setTextColor,
  obliqueAngle,
  setObliqueAngle,
  textStyle,
}) => {
  const changeBackgroundColor = (e) => {
    if (e == 1) {
      gsap.to(".changing-font-container", {
        "--starting-color": "#6a11cb", //change to --gradient-color-1
        "--ending-color": "#2575fc", //change to --gradient-color-2
        duration: 1,
        ease: "power1.inOut",
        "--thumb-color": "#000",
      });
    } else if (e == 0) {
      gsap.to(".changing-font-container", {
        "--starting-color": "#303030", //change to --gradient-color-1
        "--ending-color": "#303030", //change to --gradient-color-2
        duration: 1,
        ease: "power1.inOut",
        "--thumb-color": "#fff",
      });
    }
  };
  const handleFontWeightChange = (e) => setFontWeight(e.target.value);
  const handleTextColorChange = (e) => setTextColor(e.target.value);
  const handleObliqueAngleChange = (e) => {
    setObliqueAngle(e.target.value);
    changeBackgroundColor(e.target.value);
  };

  return (
    <div className="content-container">
      <h1 style={textStyle}>414DEV</h1>
      <div className="sliders">
        <div className="slider-container">
          <span>Raffiné</span>
          <input
            type="range"
            min="100"
            max="900"
            value={fontWeight}
            onChange={handleFontWeightChange}
          />
          <span>Voyant</span>
        </div>
        <div className="slider-container">
          <span>Classique</span>
          <input
            type="range"
            min="0"
            max="100"
            value={textColor}
            onChange={handleTextColorChange}
          />
          <span>Osé</span>
        </div>
        <div className="slider-container">
          <span>Minimaliste</span>
          <input
            type="range"
            min="0"
            max="1"
            value={obliqueAngle}
            onChange={handleObliqueAngleChange}
          />
          <span>Excentrique</span>
        </div>
      </div>
    </div>
  );
};

export default Content;
