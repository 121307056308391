import { React } from "react";
import "./SEO.css";
import ChangingImageAndBlackText from "../Components/Desktop/ChangingImageAndBlackText/ChangingImageAndBlackText";
import TextImageBlock from "../Components/Desktop/TextImageBlock/TextImageBlock";
import ImageRectangle from "../Components/Desktop/ImageRectangle/ImageRectangle";
import MobileLandingPanel from "../Components/Mobile/MobileLandingPanel/MobileLandingPanel";
import MobileContent from "../Components/Mobile/MobileContent/MobileContent";
import MobileImage from "../Components/Mobile/MobileImage/MobileImage";
import ContactForm from "../../MainPage/MainPageComponents/ContactForm/ContactForm";
import Explainations from "./Explainations/Explainations";
import Arguments from "./Arguments/Arguments";
import "../AnnexePages.css";

const SEO = ({
  setCurrentPage = () => {},
  urls = [
    "/media/img/Image_2_Accueil.png",
    "/media/img/Image_2_Accueil.png",
    "/media/img/Image_3_Accueil.png",
  ],
  black_box_title = "Marketing Digital",
  black_box_content = "Votre Partenaire Marketing",
  TextImageBlock_url = "/media/img/Marketing/Image_1_Marketing.png",
  TextImageBlock_titre = "Boostez Votre Marque",
  TextImageBlock_text = "Chez 414 Dev, nous gérons l'intégralité de votre marketing digital, de la stratégie aux actions concrètes, pour renforcer votre présence en ligne. Nous optimisons également votre SEO afin que votre site soit facilement visible par vos clients cibles. Grâce à notre expertise, vous profitez d'une meilleure position dans les résultats de recherche et d'une visibilité accrue, vous permettant ainsi de capter plus efficacement l'attention de vos futurs clients",
  ImageRectangle_urls = [
    [
      "/media/img/Marketing/Image_2_Marketing.png",
      "/media/img/Marketing/Image_3_Marketing.png",
      "Nous vous accompagnons à chaque étape du marketing digital, de la stratégie à l'action",
      "Nous nous engageons à vous offrir une totale transparence tout au long du processus",
    ],
  ],
  isMobile = false,
}) => {
  return (
    <section className={`SEO ${isMobile ? "mobile" : ""} annexe_page`}>
      {!isMobile && (
        <div className="details_wrapper">
          <ChangingImageAndBlackText
            urls={urls}
            black_box_content={black_box_content}
            black_box_title={black_box_title}
          />
          <TextImageBlock
            url={TextImageBlock_url}
            titre={TextImageBlock_titre}
            text={TextImageBlock_text}
          />
          <ImageRectangle urls={ImageRectangle_urls} />
        </div>
      )}
      {isMobile && (
        <div className="mobile_wrapper">
          <MobileLandingPanel
            title={black_box_title}
            subtitle={black_box_content}
          />
          <MobileContent
            title={TextImageBlock_titre}
            subtitle={TextImageBlock_text}
            content={`${ImageRectangle_urls[0][2]} ${ImageRectangle_urls[0][3]}`}
          />
          <MobileImage url={TextImageBlock_url} />
        </div>
      )}
      <Explainations isMobile={isMobile} />
      <Arguments isMobile={isMobile} />
      <ContactForm isAnnexePage={true} />
    </section>
  );
};

export default SEO;
