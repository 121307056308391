import React from "react";
import { gsap } from "gsap";
import "./Description.css";
import Row from "./Row/Row";
import Header from "./Header/Header";

const Description = () => {
  const header = {
    title: "414Dev",
    description: "Agence Web",
  };

  const row1 = [
    {
      title: "Professionnel",
      description:
        "Chez 414 Dev, nous nous engageons à mener vos projets avec le plus grand professionnalisme, en mettant à votre disposition notre expertise et notre savoir-faire",
    },
    {
      title: "Transparent",
      description:
        "Nous vous partageons chaque étape du processus, de la conception à la réalisation, afin de vous garantir des solutions sur mesure, parfaitement adaptées à vos besoins",
    },
  ];

  const row2 = [
    {
      title: "Créatif",
      description:
        "Notre équipe de créatifs est dédiée à concevoir le design idéal pour votre marque, en assurant une identité visuelle unique qui reflète vos valeurs et attire votre public cible.",
    },
    {
      title: "Attentif",
      description:
        "Nous prenons soin de vos projets avec une attention particulière et mettons régulièrement à jour nos outils pour toujours vous garantir une qualité optimale.",
    },
  ];

  return (
    <div className="description">
      <Header header={header} />
      <div className="rows-container">
        <div className="row-container">
          <Row blocks={row1} key={1} />
        </div>
        <div className="row-container">
          <Row blocks={row2} key={2} />
        </div>
      </div>
    </div>
  );
};

export default Description;
