import "./CenteredExplainations.css";
import { useEffect, useRef } from "react";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

//Centered title and content in a little box under the title, with a background color and inset shadow
const CenteredExplainations = ({
  title = "Qu'est-ce que le SEO ?",
  content = "Le SEO (Search Engine Optimization) est une technique pour améliorer la visibilité de votre site web dans les résultats de recherche. Il vise à attirer naturellement plus de visiteurs en optimisant votre site pour être facilement trouvable par les moteurs de recherche comme Google ou Bing.",
  isMobile = false,
}) => {
  const explainations_wrapper = useRef(null);

  useEffect(() => {
    if (!isMobile) {
      const tl = gsap.timeline({
        scrollTrigger: {
          trigger: explainations_wrapper.current,
          start: "top 95%",
          end: "+=200",
          scrub: 1,
          toggleActions: "play none none none",
        },
      });

      tl.fromTo(
        explainations_wrapper.current,
        { opacity: 0, filter: "blur(4px)" },
        { opacity: 1, filter: "blur(0px)", duration: 1, ease: "power1.in" }
      );
      return () => {
        tl.kill();
      };
    }
  }, [isMobile]);

  return (
    <section className="explaination_wrapper">
      <div className="explaination_container" ref={explainations_wrapper}>
        <div className="explaination_title">
          <h2>{title}</h2>
        </div>
        <div className="explaination_content">
          <p>{content}</p>
        </div>
      </div>
    </section>
  );
};

export default CenteredExplainations;
