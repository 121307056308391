import React, { useEffect, useRef } from "react";
import { gsap } from "gsap";
import Description from "../../../MainPage/MainPageComponents/Description/Description";
import ImagesTexts from "../../../MainPage/MainPageComponents/ImagesTexts/ImagesTexts";
import ChangingFont from "../../../MainPage/MainPageComponents/ChangingFont/ChangingFont";
import ContactForm from "../../../MainPage/MainPageComponents/ContactForm/ContactForm";
import "./MobileMainPage.css";

const MobileMainPage = () => {
  const containerRef = useRef(null);

  useEffect(() => {
    const container = containerRef.current;

    gsap.to(container, {
      opacity: 1,
      duration: 0.5,
      ease: "power2.inOut",
    });
  }, []);

  return (
    <div ref={containerRef} className="mobile-main-page">
      <Description isMobile={true} />
      <ImagesTexts isMobile={true} />
      <ChangingFont isMobile={true} />
      <ContactForm isMobile={true} />
    </div>
  );
};

export default MobileMainPage;
