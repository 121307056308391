import "./Row.css";
import Block from "./Block/Block";

const Row = ({ blocks }) => {
  return (
    <div className="row">
      {blocks.map((block, index) => (
        <Block block={block} key={index} />
      ))}
    </div>
  );
};

export default Row;
