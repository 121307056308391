import { React, useRef, useEffect } from "react";
import "./Websites.css";
import "../AnnexePages.css";
import ChangingImageAndBlackText from "../Components/Desktop/ChangingImageAndBlackText/ChangingImageAndBlackText";
import TextImageBlock from "../Components/Desktop/TextImageBlock/TextImageBlock";
import ImageRectangle from "../Components/Desktop/ImageRectangle/ImageRectangle";
import MobileLandingPanel from "../Components/Mobile/MobileLandingPanel/MobileLandingPanel";
import MobileContent from "../Components/Mobile/MobileContent/MobileContent";
import MobileImage from "../Components/Mobile/MobileImage/MobileImage";
import ContactForm from "../../MainPage/MainPageComponents/ContactForm/ContactForm";
import Explainations from "../SEO/Explainations/Explainations";
import Arguments from "../SEO/Arguments/Arguments";

const Websites = ({
  setCurrentPage = () => {},
  urls = [
    "/media/img/Image_1_Accueil.png",
    "/media/img/Image_1_Accueil.png",
    "/media/img/Image_1_Accueil.png",
  ],
  black_box_title = "Web Design",
  black_box_content = "Vos Sites Webs Sur Mesure",
  TextImageBlock_url = "/media/img/Website/Image_1_WebDesign.png",
  TextImageBlock_titre = "Votre Site, Notre Mission",
  TextImageBlock_text = "Chez 414 Dev, notre mission est de transformer vos idées en projets web réussis. Nous vous accompagnons à chaque étape, en vous garantissant un service personnalisé et un professionnalisme sans faille. Confiez-nous vos projets web, et bénéficiez de toute l'expertise et du soin que nous apportons à chacun de nos clients.",
  ImageRectangle_urls = [
    [
      "/media/img/Website/Image_2_WebDesign.png",
      "/media/img/Website/Image_3_WebDesign.png",
      "Nous avons nous occupons de tout pour rendre votre site le plus attractif possible",
      "Nous travaillons avec vous pour comprendre vos besoins et vos objectifs",
    ],
  ],
  isMobile = false,
}) => {
  const sliderRef = useRef(null);
  const sectionRef = useRef(null);

  useEffect(() => {
    if (!isMobile) {
      const slider = sliderRef.current;
      const handleScroll = () => {
        const scrollPosition = window.scrollY;
        const windowHeight = window.innerHeight;
        const documentHeight = sectionRef.current.scrollHeight;

        // Calculate the height of the slider based on scroll position
        const sliderHeight =
          (scrollPosition / (documentHeight - windowHeight)) * 100;
        slider.style.height = `${Math.min(sliderHeight, 100)}%`;
      };
      window.addEventListener("scroll", handleScroll);
      return () => window.removeEventListener("scroll", handleScroll);
    }
  }, []);

  return (
    <section
      className={`Websites ${isMobile ? "mobile" : ""} annexe_page`}
      ref={sectionRef}
    >
      {!isMobile && (
        <div className="details_wrapper">
          <ChangingImageAndBlackText
            urls={urls}
            black_box_content={black_box_content}
            black_box_title={black_box_title}
          />
          <TextImageBlock
            url={TextImageBlock_url}
            titre={TextImageBlock_titre}
            text={TextImageBlock_text}
          />
          <ImageRectangle urls={ImageRectangle_urls} />
          <div className="slider" ref={sliderRef} />
        </div>
      )}
      {isMobile && (
        <div className="mobile_wrapper">
          <MobileLandingPanel
            title={black_box_title}
            subtitle={black_box_content}
          />
          <MobileContent
            title={TextImageBlock_titre}
            subtitle={TextImageBlock_text}
            content={`${ImageRectangle_urls[0][2]} ${ImageRectangle_urls[0][3]}`}
          />
          <MobileImage url={TextImageBlock_url} />
        </div>
      )}
      <Arguments
        isMobile={isMobile}
        title="Notre Processus de Développement"
        titles={[
          "Rendez-vous initial",
          "Design initial",
          "Développement",
          "Retour permanent",
        ]}
        contents={[
          "Nous établissons un premier rendez-vous pour comprendre votre entreprise, qui vous êtes et votre identité de marque. Ensuite, nous regardons vos objectifs et vos attentes. Enfin, nous définissons un plan de projet ainsi qu'un budget.",
          "Nous créons un design initial sur mesure pour votre site web. Nous prenons en compte vos idées, vos couleurs, vos images et vos textes, ou créons tout cela de zéro. Nous vous donnerons un design qui reflète votre identité de marque et qui satisfait vos objectifs.",
          "C'est là que commence le développement, nous créons le site sur mesure. Nous commençons d'abord par la version mobile, pour garantir un design responsive. Ensuite, une version tablette, puis ordinateur portables et enfin grand écran. Cette méthode assure un design optimal pour tout les utilisateurs.",
          "Durant le développement, nous restons en contact avec vous pour vous donner des informations sur l'avancement du projet. Nous vérifions également que tout fonctionne correctement et que vous êtes satisfait du résultat. Nous nous engageons à ne rien sortir tant que vous n'avez pas tout valider et êtes entièrement satisfait.",
        ]}
      />
      <ContactForm isAnnexePage={true} />
    </section>
  );
};

export default Websites;
