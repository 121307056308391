import React, { useEffect, useRef } from "react";
import { gsap } from "gsap";
import "./MobileImage.css";

const MobileImage = ({ url = "/media/img/test.png" }) => {
  const imageRef = useRef(null);

  useEffect(() => {
    gsap.fromTo(
      imageRef.current,
      { scale: 2 },
      {
        scale: 1,
        duration: 2,
        ease: "power1.out",
        scrollTrigger: {
          trigger: imageRef.current,
          start: "top bottom",
          end: "bottom bottom",
          scrub: 1,
        },
      }
    );
  }, []);

  return (
    <div className="mobile-image-container">
      <div className="mobile-image-wrapper">
        <div className="mobile-image-boundary">
          <img
            ref={imageRef}
            src={url}
            alt="Mobile Image"
            className="mobile-image"
          />
        </div>
      </div>
    </div>
  );
};

export default MobileImage;
