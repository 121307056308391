import "./ImageText.css";
import { useEffect, useRef, useState } from "react";
import { ScrollTrigger } from "gsap/ScrollTrigger";

import gsap from "gsap";

gsap.registerPlugin(ScrollTrigger);

const ImageText = ({
  image,
  title,
  text,
  wrapperAnimation,
  isMobile,
  setCurrentPage = () => {},
}) => {
  const imageRef = useRef(null);
  const titleRef = useRef(null);
  const textRef = useRef(null);
  const [isHovered, setIsHovered] = useState(false);

  useEffect(() => {
    const imageElement = imageRef.current;
    const titleElement = titleRef.current;
    const textElement = textRef.current;

    ScrollTrigger.create({
      trigger: imageElement,
      start: "top 80%",
      end: "bottom 20%",
      onEnter: () => {
        gsap.to(imageElement, {
          scale: 1,
          duration: 1.5,
          ease: "power3.out",
          container: wrapperAnimation,
        });
      },
      onLeave: () => {
        gsap.to(imageElement, {
          scale: 1.1,
          duration: 1.5,
          ease: "power3.in",
          container: wrapperAnimation,
        });
      },
      toggleActions: "play reverse play reverse",
    });

    gsap.set([titleElement, textElement], { opacity: 0 });
    ScrollTrigger.create({
      trigger: titleElement,
      start: "top 90%",
      onEnter: () => {
        gsap.fromTo(
          titleElement,
          { opacity: 0, y: 50 },
          { opacity: 1, y: 0, duration: 0.8, ease: "power3.out" }
        );
      },
      once: true,
    });

    ScrollTrigger.create({
      trigger: textElement,
      start: "top 90%",
      onEnter: () => {
        gsap.fromTo(
          textElement,
          { opacity: 0, y: 30 },
          { opacity: 1, y: 0, duration: 0.8, delay: 0.2, ease: "power3.out" }
        );
      },
      once: true,
    });
  }, []);

  const handleMouseEnter = () => {
    if (!isMobile) setIsHovered(true);
  };

  const handleMouseLeave = () => {
    if (!isMobile) setIsHovered(false);
  };

  return (
    <div
      className={`image-text ${isHovered ? "hovered" : ""}`}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onClick={() => setCurrentPage()}
    >
      <div className="image-container">
        <img
          ref={imageRef}
          src={image}
          alt=""
          className={`image ${isHovered  ? "hovered" : ""}`}
        />
      </div>
      <div className="text-container">
        <h2 className="title" ref={titleRef}>
          {title}
        </h2>
        <p className="text" ref={textRef}>
          {text}
        </p>
      </div>
    </div>
  );
};

export default ImageText;
