import React, { useEffect, useRef, useState } from "react";
import gsap from "gsap";
import "./MobileMenu.css";

const MobileMenu = ({ setCurrentPage = () => {}, currentPage }) => {
  const menuRef = useRef();
  const linksRef = useRef();
  const toggleRef = useRef();
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    const animateMenu = (open) => {
      gsap.to(menuRef.current, {
        height: open ? "auto" : "60px",
        duration: 0.3,
        ease: "power1.inOut",
      });
      gsap.to(linksRef.current.children, {
        opacity: open ? 1 : 0,
        y: open ? 0 : -20,
        duration: 0.3,
        stagger: open ? 0.05 : 0,
        ease: "power1.inOut",
      });
    };

    animateMenu(isOpen);

    const handleOutsideClick = (event) => {
      if (
        isOpen &&
        menuRef.current &&
        !menuRef.current.contains(event.target)
      ) {
        setIsOpen(false);
      }
    };

    document.addEventListener("click", handleOutsideClick);
    document.addEventListener("touchstart", handleOutsideClick);

    return () => {
      document.removeEventListener("click", handleOutsideClick);
      document.removeEventListener("touchstart", handleOutsideClick);
    };
  }, [isOpen]);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    if (isOpen) {
      setIsOpen(false);
    }
  }, [currentPage]);

  const handleLinkClick = (page) => {
    setCurrentPage(page);
    setIsOpen(false);
  };

  return (
    <div className="mobile_menu" ref={menuRef}>
      <div className="mobile_menu_header">
        <img src="/logo.png" alt="Logo" className="mobile_logo" />
        <div className="mobile_toggle" ref={toggleRef} onClick={toggleMenu}>
          <div className="bar"></div>
          <div className="bar"></div>
          <div className="bar"></div>
        </div>
      </div>
      <div className="mobile_links" ref={linksRef}>
        <div
          className="mobile_link"
          onClick={() => handleLinkClick("mainPage")}
        >
          <h3>Accueil</h3>
        </div>
        <div
          className="mobile_link"
          onClick={() => handleLinkClick("webDesign")}
        >
          <h3>WebDesign</h3>
        </div>
        <div className="mobile_link" onClick={() => handleLinkClick("SEO")}>
          <h3>SEO</h3>
        </div>
        <div
          className="mobile_link"
          onClick={() => handleLinkClick("sous-Traitance")}
        >
          <h3>Sous-Traitance</h3>
        </div>
        <div className="mobile_link">
          <a
            href="/media/documents/CGU_414Dev.pdf"
            target="_blank"
            rel="noopener noreferrer"
          >
            <h3>CGU</h3>
          </a>
        </div>
      </div>
    </div>
  );
};

export default MobileMenu;
