import { React, useEffect, useRef } from "react";
import "./ChangingImageAndBlackText.css";
import gsap from "gsap";

//DO NOT USE THIS COMPONENT, IT'S MODIFIED FOR 414DEV. USE THE ONE FROM FLYTIPS WITH THE SAME NAME
const ChangingImageAndBlackText = ({
  urls = [
    "/img/Image_Déroulant_1_1.png",
    "/img/Image_Déroulant_1_1.png",
    "/img/Image_Déroulant_1_1.png",
  ],
  black_box_title = "Branding",
  black_box_content = "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer at turpis nec enim ornare hendrerit. Nulla lacinia viverra odio eget.",
}) => {
  const img_wrapper = useRef(null);
  const boxRef = useRef(null);
  useEffect(() => {
    const opacity_tween = gsap.fromTo(
      img_wrapper.current,
      {
        opacity: 0,
      },
      {
        opacity: 1,
        duration: 1,
        ease: "power2.inOut",
      }
    );

    const border_tween = gsap.to(
      boxRef.current,

      {
        "--left": "20%",
        "--right": "80%",
        duration: 1,
        delay: 1,
        ease: "power2.inOut",
      }
    );
    return () => {
      opacity_tween.kill();
      border_tween.kill();
    };
  }, []);

  return (
    <>
      <div className="ChangingImageAndBlackText" ref={boxRef}>
        <div className="row top">
          <div className="img_wrapper" ref={img_wrapper}>
            <img src={urls[0]} className="active" alt="ChangingImage" />
          </div>
          <div className="black box">
            <h1>{black_box_title}</h1>
            <h3>{black_box_content}</h3>
          </div>
        </div>
      </div>
    </>
  );
};
export default ChangingImageAndBlackText;
