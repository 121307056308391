import React, { useRef, useEffect } from "react";
import { gsap } from "gsap";
import "./MobileLandingPanel.css";

const MobileLandingPanel = ({ title, subtitle }) => {
  const texts = useRef([]);

  useEffect(() => {
    gsap.fromTo(
      texts.current,
      {
        opacity: 0,
        y: 20,
      },
      {
        opacity: 1,
        y: 0,
        stagger: 0.1,
        duration: 0.5,
        ease: "power1.out",
      }
    );
  }, []);
  return (
    <div className="mobile-landing-panel">
      <h1 className="title" ref={(el) => (texts.current[0] = el)}>
        {title}
      </h1>
      <h2 className="subtitle" ref={(el) => (texts.current[1] = el)}>
        {subtitle}
      </h2>
    </div>
  );
};

export default MobileLandingPanel;
