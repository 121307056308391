import React, { useEffect, useState } from "react";
import "./Mobile.css";
import MainPage from "../../MainPage/MainPage";
import Websites from "../../AnnexePages/Websites/Websites";
import gsap from "gsap";
import SEO from "../../AnnexePages/SEO/SEO";
import SousTraitance from "../../AnnexePages/Sous-Traitance/Sous-Traitance";
import MobileMainPage from "./MobileMainPage/MobileMainPage";
import MobileMenu from "./MobileMenu/MobileMenu";

const Mobile = () => {
  const [currentPage, setCurrentPage] = useState("mainPage");
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [currentPage]);
  useEffect(() => {
    gsap.to(".root", {
      height: "auto",
    });
  }, []);
  return (
    <div className="mobile">
      <div className="mobile_content"></div>
      <MobileMenu setCurrentPage={setCurrentPage} />
      {currentPage === "mainPage" && <MobileMainPage />}
      {currentPage === "webDesign" && <Websites isMobile={true} />}
      {currentPage === "SEO" && <SEO isMobile={true} />}
      {currentPage === "sous-Traitance" && <SousTraitance isMobile={true} />}
    </div>
  );
};

export default Mobile;
