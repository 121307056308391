import CenteredExplainations from "./CenteredExplainations/CenteredExplainations";
import "./Explainations.css";

const Explainations = ({ isMobile = false }) => {
  return (
    <section className="explainations_wrapper">
      <div className="container first">
        <CenteredExplainations isMobile={isMobile} />
      </div>
      <div className="container">
        <CenteredExplainations
          isMobile={isMobile}
          title="Comment ça marche ?"
          content="Les moteurs de recherche utilisent des algorithmes pour indexer les sites web et déterminer leur pertinence pour une requête spécifique. Le SEO vise à optimiser votre site pour ces algorithmes, en améliorant la structure, le contenu et les liens vers votre site."
        />
      </div>
    </section>
  );
};

export default Explainations;
