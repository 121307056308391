import "./Arguments.css";
import "../Explainations/CenteredExplainations/CenteredExplainations.css";
import CenteredExplainations from "../Explainations/CenteredExplainations/CenteredExplainations";

//Take title and contents as props
//Display a title and a list of centeredExplainations
//CenteredExplainations is a component that takes a title and a content as props and display them in a centered way
//TO BE USED IN OTHER PROJECTS AS WELL

const Arguments = ({
  isMobile = false,
  title = "Notre Approche SEO",
  titles = [
    "Nouvelle technologie",
    "Optimisation par KeyWord",
    "Démarquation de la concurrence",
    "On-Page SEO",
  ],
  contents = [
    "Nous utilisons les dernières technologies du web pour nous assurer de rester à la pointe et vous offrir un site rapide et efficace. Deux qualités très reconnues par les moteurs de recherche pour déterminer quels sont les sites les plus pertinents pour une requête.",
    "Les moteurs de recherches scan les textes de chaque page pour déterminer leur thème. Nous optimisons votre site web pour les mots-clés les plus pertinents.  Cela vous permet de gagner en visibilité et de vous démarquer de la concurrence.",
    "L'utilisation de technologies récentes vous permet aussi de vous démarquer de la concurrence, généralement sur des thèmes similaires entre eux et des langages anciens (typiquement wordpress), qui sont dépréciés par les moteurs de recherches.",
    "Un ensemble de techniques visant à créer un site qui semble changer de page en page mais reste en réalité sur la même. Noter qu'il n'y a aucun / dans notre url. Cette technique rend la transition entre les pages quasiment instantanée.",
  ],
}) => {
  return (
    <section className="arguments_wrapper">
      <h2 className="arguments_title">{title}</h2>
      <div className="arguments_container">
        {titles.map((title, index) => (
          <CenteredExplainations
            key={index}
            title={title}
            content={contents[index]}
            isMobile={isMobile}
          />
        ))}
      </div>
    </section>
  );
};

export default Arguments;
