import React, { useRef, useEffect, useState, useLayoutEffect } from "react";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import "./MainPage.css";
import Description from "./MainPageComponents/Description/Description";
import ImagesTexts from "./MainPageComponents/ImagesTexts/ImagesTexts";
import ChangingFont from "./MainPageComponents/ChangingFont/ChangingFont";
import ContactForm from "./MainPageComponents/ContactForm/ContactForm";

gsap.registerPlugin(ScrollTrigger);

const MainPage = ({ setCurrentPage }) => {
  const containerRef = useRef(null);
  const descriptionRef = useRef(null);
  const imagesTextsRef = useRef(null);
  const changingFontRef = useRef(null);
  const contactFormRef = useRef(null);
  const sliderRef = useRef(null);
  const [wrapperAnimation, setWrapperAnimation] = useState(null);

  useLayoutEffect(() => {
    const container = containerRef.current;
    if (!container) return; // Ensure container is available

    const panelsArray = gsap.utils.toArray(".panel");

    const calculateScroll = () => {
      const totalScroll = panelsArray.reduce(
        (acc, panel) => acc + panel.offsetWidth,
        0
      );

      return totalScroll - window.innerWidth * 0.9;
    };

    let adjustedScroll = calculateScroll();
    gsap.set(".desktop", {
      height: "auto",
    });
    const tl = gsap.timeline({
      scrollTrigger: {
        trigger: container,
        pin: true,
        scrub: 1,
        end: `+=${adjustedScroll}`,
        invalidateOnRefresh: true,
        onUpdate: (self) => {
          const progress = self.progress;
          gsap.to(sliderRef.current, { width: `${progress * 100}%` });
        },

      },
    });

    tl.fromTo(imagesTextsRef.current, { xPercent: 0 }, { xPercent: -100 });
    tl.fromTo(
      descriptionRef.current,
      { filter: "brightness(100%) blur(0px)" },
      { filter: "brightness(0%) blur(10px)" },
      "<"
    );
    tl.fromTo(changingFontRef.current, { xPercent: -100 }, { xPercent: -200 });
    tl.fromTo(
      imagesTextsRef.current,
      { filter: "brightness(100%) blur(0px)" },
      { filter: "brightness(0%) blur(10px)" },
      "<"
    );
    tl.fromTo(contactFormRef.current, { xPercent: -200 }, { xPercent: -300 });

    setWrapperAnimation(tl);

    // Force a scroll update
    window.scrollTo(0, 0);

    return () => {
      gsap.set(".desktop", {
        height: "auto",
      });
      ScrollTrigger.killAll();
    };
  }, []);

  return (
    <div className="main-page">
      <div ref={containerRef} className="panels-container">
        <div className="panel" ref={descriptionRef} style={{ zIndex: 1 }}>
          <Description />
        </div>
        <div className="panel" ref={imagesTextsRef} style={{ zIndex: 2 }}>
          <ImagesTexts
            wrapperAnimation={wrapperAnimation}
            setCurrentPage={setCurrentPage}
          />
        </div>
        <div className="panel" ref={changingFontRef} style={{ zIndex: 3 }}>
          <ChangingFont />
        </div>
        <div className="panel" ref={contactFormRef} style={{ zIndex: 4 }}>
          <ContactForm />
        </div>
      </div>
      <div className="slider" ref={sliderRef} />
    </div>
  );
};

export default MainPage;
