import React, { useEffect, useState } from "react";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { ScrollToPlugin } from "gsap/ScrollToPlugin";
import CustomCursor from "../../CommunComponents/CustomCursor/CustomCursor";
import MainPage from "../../MainPage/MainPage";
import ImagesTexts from "../../MainPage/MainPageComponents/ImagesTexts/ImagesTexts";
import "./Desktop.css";
import Description from "../../MainPage/MainPageComponents/Description/Description";
import ChangingFont from "../../MainPage/MainPageComponents/ChangingFont/ChangingFont";
import ContactForm from "../../MainPage/MainPageComponents/ContactForm/ContactForm";
import Websites from "../../AnnexePages/Websites/Websites";
import SideMenu from "../../AnnexePages/Components/Desktop/SideMenu/SideMenu";
import SEO from "../../AnnexePages/SEO/SEO";
import SousTraitance from "../../AnnexePages/Sous-Traitance/Sous-Traitance";

gsap.registerPlugin(ScrollTrigger, ScrollToPlugin);

const Desktop = () => {
  const [currentPage, setCurrentPage] = useState("mainPage");
  const [showArrowOnFirstRender, setShowArrowOnFirstRender] = useState(true); // State to control arrow visibility

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [currentPage]);

  useEffect(() => {
    gsap.set(".desktop", {
      cursor: "none",
    });
    return () => {
      gsap.set(".desktop", {
        cursor: "default",
      });
    };
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      if (
        showArrowOnFirstRender &&
        document.documentElement.querySelector(".down_arrow")
      ) {
        gsap.to(".down_arrow", {
          opacity: 0,
          duration: 1,
          ease: "power2.inOut",
          onComplete: () => {
            setShowArrowOnFirstRender(false);
          },
        });
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div className="desktop">
      <SideMenu setCurrentPage={setCurrentPage} />
      <CustomCursor showArrow={showArrowOnFirstRender} />
      <div className="desktop-content"></div>
      {currentPage === "mainPage" && (
        <MainPage setCurrentPage={setCurrentPage} />
      )}
      {currentPage === "webDesign" && <Websites />}
      {currentPage === "SEO" && <SEO />}
      {currentPage === "sous-Traitance" && <SousTraitance />}
    </div>
  );
};

export default Desktop;
